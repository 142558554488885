import * as React from 'react';
import { Link } from 'gatsby';
import { Card, Col } from 'react-bootstrap';
import * as styles from './content-card.module.scss';

const ContentCard = ({ image, date, excerpt, link, title }) => {
  return (
    <Col className={`d-flex align-items-stretch py-3`} lg={4}>
      <Card className={styles.contentCard} text='dark' bg='light'>
        <Card.Img className={styles.cardImage} variant='top' src={image} />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Subtitle className={`py-2`}>{date}</Card.Subtitle>
          <Card.Text>{excerpt}</Card.Text>
        </Card.Body>
        <Link
          to={link}
          className={`stretched-link ${styles.link}`}
        ></Link>
      </Card>
    </Col>
  );
};

export default ContentCard;
