import * as React from "react";
import { Row } from "react-bootstrap";
import ContentCard from "../components/content-card";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { graphql } from "gatsby";

const BlogListPage = ({ data }) => {
  return (
    <Layout>
      <Seo
        title={`Blog`}
        description={`List of the latest content from Jordan Wagner`}
      />
      <Row>
        {data.allMdx.edges.map(({ node }, idx) => (
          <ContentCard
            key={idx}
            date={node.frontmatter.date}
            excerpt={node.excerpt}
            image={node.frontmatter.image}
            link={`/${node.slug}`}
            title={node.frontmatter.title}
          />
        ))}
      </Row>
    </Layout>
  );
};

export const query = graphql`
  query blogPosts {
    allMdx(
      filter: { frontmatter: { iscontentpage: { eq: false } } }
      sort: { order: ASC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          body
          excerpt(pruneLength: 60)
          slug
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            image
            title
          }
        }
      }
    }
  }
`;

export default BlogListPage;
